<template>
  <countdown :time="timeLeft" @end="onEndCountdown">
    <template slot-scope="props">
      <div
        :class="[
          'timing-row',
          { 'is-studio7': isStudio7, 'is-pdp': isPDP },
        ]"
      >
        <div
          v-if="isDefault"
          class="timer-layout"
          :style="customJustify"
        >
          <div v-if="!isStudio7" class="end-in">Ends In:</div>
          <div>
            <v-row
              v-bind="{ justify: 'center', 'no-gutters': true }"
              class="flex-column text-center"
            >
              <div
                class="text-center"
                :class="customClass"
                :style="customStyle"
              >
                <span>{{ pad(props.days) }}</span>
              </div>
              <div
                class="label-unit text-center"
                :class="customClassUnit"
              >
                วัน
              </div>
            </v-row>
          </div>
          <div v-if="isColon" class="colon">:</div>
          <div>
            <v-row
              v-bind="{ justify: 'center', 'no-gutters': true }"
              class="flex-column text-center"
            >
              <div
                class="text-center"
                :class="customClass"
                :style="customStyle"
              >
                <span>
                  {{ pad(props.hours) }}
                </span>
              </div>
              <div
                class="label-unit text-center"
                :class="customClassUnit"
              >
                ชั่วโมง
              </div>
            </v-row>
          </div>
          <div v-if="isColon" class="colon">:</div>
          <div>
            <v-row
              v-bind="{ justify: 'center', 'no-gutters': true }"
              class="flex-column text-center"
            >
              <div
                class="text-center"
                :class="customClass"
                :style="customStyle"
              >
                <span>
                  {{ pad(props.minutes) }}
                </span>
              </div>
              <div
                class="label-unit text-center"
                :class="customClassUnit"
              >
                นาที
              </div>
            </v-row>
          </div>
          <div v-if="isColon" class="colon">:</div>
          <div>
            <v-row
              v-bind="{ justify: 'center', 'no-gutters': true }"
              class="flex-column text-center"
            >
              <div
                class="text-center"
                :class="customClass"
                :style="customStyle"
              >
                <span>
                  {{ pad(props.seconds) }}
                </span>
              </div>
              <div
                class="label-unit text-center"
                :class="customClassUnit"
              >
                วินาที
              </div>
            </v-row>
          </div>
        </div>

        <!-- Type 2 -->
        <div v-else class="timer-layout" :style="customJustify">
          <div v-if="!isStudio7" class="end-in">Ends In:</div>
          <div>
            <v-row
              v-bind="{ justify: 'center', 'no-gutters': true }"
              class="flex-column text-center"
            >
              <div class="text-center" :class="customClass">
                <div>{{ pad(props.days) }}</div>
                <div class="body-s text-center">
                  วัน
                </div>
              </div>
            </v-row>
          </div>
          <div>
            <v-row
              v-bind="{ justify: 'center', 'no-gutters': true }"
              class="flex-column text-center"
            >
              <div class="text-center" :class="customClass">
                <div>
                  {{ pad(props.hours) }}
                </div>
                <div class="body-s text-center">
                  ชั่วโมง
                </div>
              </div>
            </v-row>
          </div>
          <div>
            <v-row
              v-bind="{ justify: 'center', 'no-gutters': true }"
              class="flex-column text-center"
            >
              <div class="text-center" :class="customClass">
                <div>
                  {{ pad(props.minutes) }}
                </div>
                <div class="body-s text-center">
                  นาที
                </div>
              </div>
            </v-row>
          </div>
          <div>
            <v-row
              v-bind="{ justify: 'center', 'no-gutters': true }"
              class="flex-column text-center"
            >
              <div class="text-center" :class="customClass">
                <div>
                  {{ pad(props.seconds) }}
                </div>
                <div class="body-s text-center">
                  วินาที
                </div>
              </div>
            </v-row>
          </div>
        </div>
      </div>
    </template>
  </countdown>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: null,
    },
    timeLeft: {
      type: Number,
      required: true,
    },
    justify: {
      type: String,
      default: 'center',
    },
    dark: {
      type: Boolean,
      default: true,
    },
    isDefault: {
      type: Boolean,
      default: true,
    },
    isColon: {
      type: Boolean,
      default: true,
    },
    isStudio7: {
      type: Boolean,
      default: false,
    },
    isPDP: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    customStyle() {
      if (this.size === 'lg' || this.size === null) {
        return {
          width: '56px !important',
          height: '56px !important',
          fontSize: '1.5rem !important',
        }
      } else if (this.size === 'md') {
        return {
          width: '40px !important',
          height: '40px !important',
          fontSize: '1.4rem !important',
        }
      } else if (this.size === 'sm') {
        return {
          width: '36px !important',
          height: '36px !important',
          fontSize: '1.125rem !important',
        }
      } else if (this.size === 'xs') {
        return {
          width: '32px !important',
          height: '32px !important',
          fontSize: '1.25rem !important',
        }
      }
      return {
        width: '64px !important',
        height: '64px !important',
        fontSize: '2rem !important',
      }
    },
    customJustify() {
      if (this.justify === 'center') {
        return {
          justifyContent: 'center !important',
        }
      }
      return {
        justifyContent: `${this.justify} !important`,
      }
    },
    customClass() {
      return this.dark ? 'label-timer-dark' : 'label-timer-light'
    },
    customClassUnit() {
      return this.dark ? 'text-black' : 'text-white'
    },
  },

  methods: {
    onEndCountdown() {
      this.$emit('end')
    },
    pad(num) {
      return num > 9 ? num : '0' + num
    },
  },
}
</script>

<style lang="stylus" scoped>

.end-in {
  display: none

  @media screen and (min-width: 375px) and (max-width: 600px) {
	display: block
	font-size: 1rem
	font-weight: bold
	padding-right: 0.5rem
  }
}

.timing-row {
    display: block
    position: relative
    @media screen and (max-width: 600px) {
        &:not(.is-studio7) {
            display: flex
            justify-content: center
            position: absolute
            top: 80px
            left: 0
            width: 100dvw
            z-index: 9
            padding: 0.8rem 1rem 0.5rem 1rem

            box-shadow: 0px 1px 3px rgba(0,0,0,0.161)
            background: white
            }
    }
}

.timing-row.is-studio7:not(.is-pdp) {
    display: block
    position: relative
    @media screen and (max-width: 600px) {
        display: flex
        position: unset
        width: 100%
        z-index: 9
    }

    .label-timer-light {
        background: transparent
        > div:first-child {
            color: $color-timer-primary !important
        }
    }
}

.campaign-list {
	.timing-row {
		display: block !important
		position: relative !important
		top: unset
		left: unset
		background: unset

		.end-in {
			display: none !important
		}

		.label-timer-dark {
			background: $color-bnn-gray-medium !important
			color: white !important
		}
	}
}

.text-black {
     color: $color-bnn-gray-medium
}

.text-white {
     color: white
}

.label-timer-dark {
    background: $color-bnn-gray-medium
    color: white
    font-size: 32px
    border-radius: 8px
    width: 56px
    height: 56px
    margin auto
    font-weight bold
    display: flex
    justify-content: center
    align-items: center
    flex-wrap: wrap
    flex-direction: column
	@media screen and (min-width: 768px) {
        font-size: 36px
    }
        // @media screen and (max-width: 600px) {
		// background: white
		// color: $color-bnn-gray-medium
	//}
}

.label-timer-light {
    background: white
    color: $color-bnn-gray-medium
    font-size: 32px
    border-radius: 8px
    width: 56px
    height: 56px
    margin auto
    font-weight bold
    display: grid
    grid-template-columns: repeat(1, 1fr)
    justify-content: center
    align-items: center
    flex-wrap: wrap
    @media screen and (min-width: 768px) {
        font-size: 36px
    }
}

.is-studio7:not(.is-pdp) {
    .label-timer-light {
        background: white
        color: $color-bnn-gray-medium
        font-size: 32px
        border-radius: 8px
        width: 56px
        height: fit-content
        margin auto
        font-weight bold
        display: grid
        grid-template-columns: repeat(1, 1fr)
        justify-content: center
        align-items: center
        flex-wrap: wrap
        @media screen and (min-width: 768px) {
            font-size: 36px
        }
    }
}

.is-studio7.is-pdp {
    .label-timer-dark {
        background: white
        color: $color-bnn-gray-medium
        font-size: 24px
        border-radius: 8px
        width: 56px
        height: 56px
        margin auto
        font-weight bold
        display: grid
        grid-template-columns: repeat(1, 1fr)
        justify-content: center
        align-items: center
        flex-wrap: wrap
        @media screen and (min-width: 768px) {
            font-size: 24px
        }
    }

    .label-timer-light {
        background: white
        color: $color-bnn-gray-medium
        font-size: 24px
        border-radius: 8px
        width: 56px
        height: 56px
        margin auto
        font-weight bold
        display: grid
        grid-template-columns: repeat(1, 1fr)
        justify-content: center
        align-items: center
        flex-wrap: wrap
        @media screen and (min-width: 768px) {
            font-size: 24px
        }
    }
}

.timer-layout {
    display: flex
    justify-content: center
    align-items: center
    text-overflow: ellipsis;
    white-space: nowrap;
    gap: 12px
}

.colon {
    margin-top : -20px
    padding 0 1rem
    font-size: 2rem
    font-weight bold
    color: black

    @media screen and (max-width: 600px) {
        font-size: 1.2rem
        padding 0 0.75rem
    }
}

.label-unit {
  font-size: 1rem
  padding-top: 8px

  @media screen and (max-width: 600px) {
    font-size: 0.875rem
	padding-top: 0
  }

}
</style>
